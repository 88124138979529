.title {
  text-align: center;
}
  
/* Styling the container of the dropdown. */
.select-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F3F4F6;
  border-radius: 8px;
  color: #838191;
}
  
.selected-text {
  padding: 10px 20px;
  cursor: pointer;
}
  
/* Creating a triangle shape. */
.selected-text::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 16px;
  border: solid #838191;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
  
  /* ul {
    margin: 0;
    padding: 0;
    text-align: left;
    border-radius: 5px;
  } */

.select-container .select-options{
  background: #fff;
  border: 1px solid #E9EDF5;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: var(--shadow-box);
  z-index: 10;
  max-height: 10rem;
  overflow-y: scroll;
  transition: max-height 0.12s ease-in-out;
}

.select-container .select-options::-webkit-scrollbar{
  background: #fff;
  width: 5px;
}

.select-container .select-options::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 20px;
  /* background-color: darkgrey;
  outline: 1px solid slategrey; */
}
  
.select-options {
  margin: 0;
  padding: 0;
  text-align: left;
  border-radius: 5px;
  position: absolute;
  margin-top: 5px;
  width: 100%;
  border: 1px solid #F3F4F6;
}
  
.select-options li {
  list-style-type: none;
  padding: 6px 20px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
}

.select-option:last-child {
  border-bottom: none;
}

.select-option:hover {
  background-color: #d9d9d9;
}

.show-dropdown-options {
  min-height: 50px;
  opacity: 1;
  visibility: visible;
}

.hide-dropdown-options {
  min-height: 0;
  opacity: 0;
  visibility: hidden;
}
  