.spinner, .spinner__spin {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid #7DE495;
    /* background: #7DE495; */
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin .9s infinite linear;
    -webkit-animation: spin .9s infinite linear;
}

.spinner__spin{
    width: 20px;
    height: 20px;
    border: 3px solid #e09c9e;
    border-top-color: #fff;
    animation: spin .9s infinite linear;
    -webkit-animation: spin .9s infinite linear;
}

@media only screen and (min-width: 230px) {
    .spinner{
        width: 15px;
        height: 15px;
    }
}

@media only screen and (min-width: 800px) {
    .spinner{
        width: 30px;
        height: 30px;
    }
}
  
@keyframes spin {
    0% {
        transform:  rotate(0deg);
    }
    100% {
         transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    0% {
        transform:  rotate(0deg);
    }
    100% {
         transform: rotate(360deg);
    }
}
