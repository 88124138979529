/*These styled override the leaflet default style*/

.leaflet-container{
    width: 100vw;
    height: 100vh;
    /* margin-top: 5rem; */
}

.leaflet-right .leaflet-control {
    margin-right: 0px;
    margin-top: 5rem;  
}

.leaflet-left .leaflet-control{
    margin-left: 0;
    margin-top: 5rem;
}

.leaflet-touch .leaflet-bar a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 40px;
    line-height: 30px;
}

.other {
    width: 100%;
    z-index: 500;
    position: absolute;
    top: 200px;
}


.zoom__control{
    position: absolute;
    top: 20rem;
    right: 10rem;
    background-color: red;
    z-index: 500;
}

/*************Custom Marker Style***************************/
.jsx__marker{
    position: relative;
    display: grid;
    place-items: center;
    font-size: 1rem;
    color: #fff;
    /* width: 40px;
    height: 40px; */
    border: 2px solid #4D9200;
    border-radius: 50%;
    background: rgba(77, 146, 0, 0.3);
}

.jsx__marker .span_icon{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #4D9200;
}

.device__marker {
    /* display: inline-flex;
    justify-content: center;
    align-items: center; */
    
}

.device__marker .pin_icon{
    width: 50px;
    height: 50px;
    background-color: #4D9200;
    border-radius: 50% 50% 0 50%;
    position:relative;
    transform: rotate(45deg);
    color: #4D9200;
}
.pin_icon .pin_inner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px ;
    height: 45px ;
    background: #FFF;
    position: absolute ;
    left: 5% ;
    top: 5% ;
    border-radius: 50%;
    font-size: 1.2rem;
    transform: rotate(-45deg);
}

.popup_new{
    /* background: red; */
    position: absolute;
    top: -7rem;
    right: -6rem;
    margin-left: 0;
}
.adjust_popup{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
}